// import { pathToRegexp } from "path-to-regexp";


export default '/press-releases/@id';
// export const route = (pageContext: any) => {
//     // We can use RegExp / any library we want
//     if (!pathToRegexp("/press-releases/:id").test(pageContext.urlPathname)) {
//         return false
//     }
//     const id = pageContext.urlPathname.split('/')[2]

//     return {
//         // Make `id` available as pageContext.routeParams.id
//         routeParams: { id }
//     }
// }